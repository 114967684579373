<template>
  <div>
    <h2>Merge Internal User</h2>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <div class="row">
      <div class="col-6">
        <div class="search-wrapper">
          <h3>Select User by User ID</h3>
          <b-form-group
            :label="!readonly ? 'This is the losing User' : ''"
            label-for="userToLose"
          >
            <v-select
              id="userToLose"
              v-model="userToLose"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="userOptions"
              placeholder=""
              name="userToLose"
              :disabled="readonly"
            />
          </b-form-group>
        </div>
      </div>

      <div
        v-if="userToLose && !readonly"
        class="col-6"
      >
        <div class="search-wrapper">
          <h3>Select User by User ID</h3>
          <b-form-group
            label="This is the winning User"
            label-for="userToWin"
          >
            <v-select
              id="userToWin"
              v-model="userToWin"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="userOptions"
              placeholder=""
              name="userToWin"
            />
          </b-form-group>
        </div>

        <div class="mt-1 float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click="mergeUsers()"
          >
            Merge Users
          </b-button>
        </div>

      </div>
    </div>
  
    <b-modal
      id="modal-merge-user"
      ok-only
      ok-variant="danger"
      ok-title="Merge Client"
      modal-class="modal-danger"
      centered
      title="Are you sure?"
      hide-backdrop
      size="md"
      @ok="confirmMergeUser()"
    >
      <template #modal-header="">
        <div
          class="
            d-flex
            mt-1
            mb-n3
            text-center
            flex-column
            justify-content-center
          "
        >
          <img
            class="alert-icon"
            src="../../assets/images/icons/alert.png"
          >
          <h1 class="text-center">
            <strong>Are you sure?</strong>
          </h1>
          <div v-if="userToWin !== null">
            You are about to merge these users.  This action cannot be undone.
          </div>
        </div>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(238,118,119,255)'"
              variant="outline-danger"
              size="md"
              @click="cancel()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(71,109,174,255)'"
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="ok()"
            >
              Merge
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BFormGroup,
  BButton,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import APIService from "@core/utils/APIService";
import vSelect from "vue-select";

const apiService = new APIService();

export default {
  components: {
    BFormGroup,
    BBreadcrumb,
    BModal,
    BButton,
    vSelect,
  },
  data() {
    return {
      userToLose: null,
      userToWin: null,

      userOptions: [],

      readonly: false,

      breadcrumbItems: [
        {
          text: "Admin",
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Merge Internal User",
          active: true,
        },
      ],
    };
  },
  async created() {
    try {
      this.loadUsers();
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    mergeUsers() {
      this.$bvModal.show("modal-merge-user");
    },
    loadUsers() {
      apiService.get('users/user')
          .then(res => {
            this.userOptions = res.data.Users.map(i => ({
              title: i.UserAccountID + " - " + i.LastName + ", " + i.FirstName + " - " + i.EMail,
              value: i.UserAccountID,
              name: i.LastName + ", " + i.FirstName
            }))
          })
          .catch(error => error)
    },
    confirmMergeUser() {
      if (!this.userToLose || !this.userToWin) {
        return;
      }
      apiService
          .get("user/" + this.userToWin.value + "/merge/" + this.userToLose.value)
          .then(res => {
            if (res) {
              this.userToLose = this.userToWin;
              this.readonly = true;
              this.userToWin = null;
              this.showToast("success", "top-center", 4000, "Users merged");
              this.loadUsers();
            }
          });
    },
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
  },
};
</script>

<style>
.alert-icon {
  width: 75px;
  margin: 30px auto 30px;
  display: block;
}
</style>
